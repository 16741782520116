<template>
  <footer id="footer">
    <span>Desenvolvimento: Departamento de TI - Fundimisa © 2020</span>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
  
</script>

<style scoped>
#footer {
  height: 200px;
  background-color: #222;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  
}
</style>
