<template>
    <h1>Gerenciar pedidos:</h1>
<dashboard />
  <div class="main-container">
  </div>
</template>

<script>
import Dashboard from '@/components/Dashboard.vue'

export default {
  components: { Dashboard }, 
  name: "Pedidos"
}

</script>

<style scoped>

</style>
